import { gql, useQuery } from '@apollo/client'

import { TrialChallengeUserProgress } from '../../../graphql/types'
import * as queryStates from '../../queryStates'

const GET_TRIAL_CHALLENGE_USER_PROGRESS = gql`
  query GetTrialChallengeUserProgress {
    trialChallengeUserProgress {
      id
      trialChallengeKey
      userId
    }
  }
`

interface TrialStepUserProgressData {
  trialChallengeUserProgress: TrialChallengeUserProgress[]
}

export function useTrialStepUserProgress() {
  const { data, error, loading, refetch } = useQuery<TrialStepUserProgressData>(
    GET_TRIAL_CHALLENGE_USER_PROGRESS,
    {
      context: {
        source: 'useTrialStepUserProgress.ts',
      },
    }
  )
  const trialChallengeUserProgress = data?.trialChallengeUserProgress

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(
      `An error occurred while fetching trial challenge user progress${
        error.message ? ': ' + error.message : ''
      }`
    )
  } else {
    status = queryStates.success()
  }

  return {
    trialChallengeUserProgress,
    status,
    refetch,
  }
}
